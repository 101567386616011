import React, { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import BorrisPNG from './borris.png'
import getMyLocation from './get-location'
import You from './you.png'
import useInterval from '@use-it/interval';
import getBorrisLocation from './get-borris.location.js'
import {NotificationContainer, NotificationManager} from 'react-notifications';


import Weak from './weak.png'
import Fair from './fair.png'
import Good from './good.png'
import Excellent from './excellent.png'

import 'react-notifications/lib/notifications.css';
import './App.scss';

function toTimestamp(d){
  return [d.getMonth()+1,
    d.getDate(),
    d.getFullYear()].join('/')+' '+
   [d.getHours(),
    d.getMinutes(),
    d.getSeconds()].join(':');
 }

const Marker = ({image, id}) => <div
  id={id}
  style={{
    backgroundImage: `url(${image})`,
    width: '80px',
    height: '80px',
    'backgroundSize': 'contain',
    'backgroundRepeat': 'no-repeat',
    'marginLeft': '-40px',
    'marginTop': '-40px'
  }}>
</div>;

const timeAgo = (date) => {
  const date1 = new Date();
  let diffTime = Math.round(Math.abs(date1 - date) / 1000);
  const diffDays = Math.floor(diffTime / 60 / 60);

  const minutes = Math.floor(diffTime / 60);

  diffTime -= minutes * 60;

  let res = "";
  if (diffDays > 0) {
    res += diffDays + ' days, '
  }

  if (minutes > 0) {
    res += minutes + ' minutes, '
  }

  res += diffTime + " seconds ago"
  return res;
}


const WifiStrength = ({ strength, lastUpdate, lastGPSUpdate, hotspotName }) => {
  return strength < 0 && <div className="wifi-strength">
    <div>Connected with: <span style={{ color: 'green' }}>{hotspotName}</span></div>
    <div>Connection Strength with borris: {strength}</div>

    { lastUpdate && <div>Last connection strength update: {toTimestamp(new Date(lastUpdate))}</div>}
    { lastUpdate && <div>{timeAgo(new Date(lastUpdate))}</div>}

    { lastGPSUpdate && <div>Last location update: {toTimestamp(new Date(lastGPSUpdate))}</div>}
    { lastGPSUpdate && <div>{timeAgo(new Date(lastGPSUpdate))}</div>}

    { strength > -50 && <span className="excellent">Exellent <img src={Excellent} /></span> }
    { strength > -60 && strength <= -50 && <span className="good">Good <img src={Good} /></span> }
    { strength > -70 && strength <= -60 && <span className="fair">Fair <img src={Fair} /></span> }
    { strength <= -70 && <span className="weak">Weak <img src={Weak} /></span> }
    </div>
}

const defaultProps = {
  center: {
    lat: 53.251879,
    lng: 6.154741
  },
  zoom: 15
};


// document.getElementById('you-marker')

function App() {

  const [mylocation, setmylocation] = useState({latitude: 0, longitude: 0})
  const [borrisLocation, setBorrisLocation] = useState({latitude:0, longitude:0 })
  const [notfound, setnotfound] = useState(false);

  useEffect(() => {
    getMyLocation(res => {
      setmylocation(res)
    })

  }, [])

  useInterval(() => {
    getMyLocation(res => {
      if (!res || !res.longitude) {       
        NotificationManager.error('You location is not found', 'Oh no');
      }
      console.log('MY location', res)
      setmylocation(res)
    })
  }, 2000)

  useInterval(() => {
    setnotfound(false)
  }, 10000)


  useInterval(() => {
    getBorrisLocation(res => {
      if ((!res || !res.longitude) && !notfound && !res.wifiStrength) {       
        NotificationManager.error('Borris location not found', 'Oh no');
        setnotfound(true);
      } 

      res && res.longitude && setnotfound(false)

      console.log(borrisLocation)
      setBorrisLocation(res)
    })
  }, 2000)


  const markerRef = document.getElementById('you-marker')

  return (
    <div style={{ height: '100vh', width: '100%' }}>
        <NotificationContainer/>
        <WifiStrength
          strength={borrisLocation.wifiStrength}
          lastUpdate={borrisLocation.lastUpdate}
          lastGPSUpdate={borrisLocation.lastGPSUpdate}
          hotspotName={borrisLocation.hotspotName}
        />

        <GoogleMapReact
          bootstrapURLKeys={{ key: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'? "AIzaSyDg1rPDdaSkUVRD4Ed6tNFsvco1V89AGxg" : "AIzaSyD1R-rmcWi01MZNw58I_mfIODv2YAtx8oo" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          id="map"
        >

        {mylocation && mylocation.latitude > 0 && <Marker
            lat={mylocation.latitude}
            lng={mylocation.longitude}
            image={You}
            id="you-marker"
          />
          }

          {borrisLocation && borrisLocation.latitude > 0 && <Marker
            lat={borrisLocation.latitude}
            lng={borrisLocation.longitude}
            image={BorrisPNG}
          />}

        </GoogleMapReact>
      </div>
  );
}

export default App;
