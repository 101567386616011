export default (callback) => {
    const location = window.navigator && window.navigator.geolocation
    
    if (location) {
      location.getCurrentPosition((position) => {
        callback ({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      }, (error) => {
        // poep
        // alert(error.message)
      }, {enableHighAccuracy: true, maximumAge: 10000})
    }
  }
