export default (callback) => {
    const url = "https://esp-gps.herokuapp.com/bonker"
    fetch(url).then(res => {
        res.json().then(jsonResult => {
            callback({
                accuracy: ~~jsonResult.accuracy,
                latitude: ~~jsonResult.latitude,
                longitude: ~~jsonResult.longitude,
                wifiStrength: ~~jsonResult.wifiStrength,
                lastUpdate: jsonResult.lastUpdate,
                lastGPSUpdate: jsonResult.lastGPSUpdate,
                hotspotName: jsonResult.hotspotName
            })
        })
    })
}